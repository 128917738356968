import React from 'react'
import { graphql, Link, PageProps } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

interface BlogPageProps extends PageProps {
    pageContext: {
        // TODO: Modeln
        posts: any[];
    }
}

const BlogPost: React.FC<BlogPageProps> = ({ data, path, pageContext }) => {
    return (
        <Layout>
            <SEO title='Blog' />
            <div className='text-content'>
                <ol className='list-disc list-inside'>
                    {pageContext.posts.map(post => {
                        return <li key={post.id}>
                            <Link to={post.slug} className='hover:underline'>{post.title}</Link>
                        </li>
                    })}
                </ol>
            </div>
        </Layout>
    )
}

export default BlogPost;
